import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from './components/Navbar';
import config from './config.json'; // Assuming you have a config file for the base API URL

const Blog = () => {
  const [news, setNews] = useState([]);
  const [blogs, setBlogs] = useState([]);

  // Fetch the news and blogs data from the API
  useEffect(() => {
    axios
      .get(`${config.apiUrl}/newsAndBlogs`)
      .then(response => {
        const articles = response.data.newsAndBlogs.map(article => ({
          ...article,
          featureImage: article.featureImage.replace('public\\', '') // Remove 'public\\' from image path
        }));

        // Separate News and Blog categories
        const newsArticles = articles.filter(article => article.category === 'News');
        const blogArticles = articles.filter(article => article.category === 'Blog');

        setNews(newsArticles); // Set news articles
        setBlogs(blogArticles); // Set blog articles
      })
      .catch(error => {
        console.error('Error fetching news and blogs:', error);
      });
  }, []);

  return (
    <div className='bg-slate-100 text-black'>
      <Navbar />
      <div className='bg-gradient-to-tl to-[#a855f730] from-[#3b82f630] -mt-12'>
        <div className='max-w-[920px] m-auto px-4 md:px-0 p-28'>
          <h1 className='text-5xl font-bold gradient-text'>News & Blogs</h1>
          <p className='mt-5 w-[80%]'>
            We’re dedicated to supporting educational institutions with cutting-edge AI applications that enhance efficiency, performance, and academic integrity. Our innovative tools are designed to meet the diverse needs of universities, colleges, and language centers.
          </p>
        </div>
      </div>

      <main className='max-w-[920px] m-auto px-4 md:px-0'>
        {/* Latest News Section */}
        <div className='mt-20'>
          <h2 className='text-3xl font-bold text-purple-500'>Latest News</h2>
          <p className='text-sm mt-2 w-3/4'>
            Stay updated with the latest tips, trends, and insights in the world of writing. Explore our blog for expert advice, industry news, and more.
          </p>

          <div className='grid md:grid-cols-4 grid-cols-1 gap-2 mt-5'>
            {news.map(article => (
              <div key={article._id}>
                <img
                  src={`${config.apiUrl}/${article.featureImage}`}
                  alt={article.title}
                  className='w-full h-48 rounded-md object-cover'
                />
                <p className='text-xs font-semibold mt-2'>{new Date(article.date).toLocaleDateString()}</p>
                <h3 className='font-semibold'>{article.title}</h3>
                <p className='text-[10px]'>{article.overview}</p>
                <a href="/" className='text-sm text-blue-500'>Read More</a>
              </div>
            ))}
          </div>
        </div>

        {/* Blogs Section */}
        <div className='mt-20'>
          <h2 className='text-3xl font-bold text-purple-500'>Blogs</h2>
          <p className='text-sm mt-2 w-3/4'>
            Stay updated with the latest tips, trends, and insights in the world of writing. Explore our blog for expert advice, industry news, and more.
          </p>

          <div className='grid md:grid-cols-4 grid-cols-1 gap-2 mt-5'>
            {blogs.map(article => (
              <div key={article._id}>
                <img
                  src={`${config.apiUrl}/${article.featureImage}`}
                  alt={article.title}
                  className='w-full h-48 rounded-md object-cover'
                />
                <p className='text-xs font-semibold mt-2'>{new Date(article.date).toLocaleDateString()}</p>
                <h3 className='font-semibold'>{article.title}</h3>
                <p className='text-[10px]'>{article.overview}</p>
                <a href="/" className='text-sm text-blue-500'>Read More</a>
              </div>
            ))}
          </div>
        </div>
      </main>

      <footer className='bg-white text-center p-3 mt-20'>
        <p className='text-slate-400'>© 2024 Appverse Tech LLC. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Blog;
