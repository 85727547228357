import React from 'react'
import Logo from '../images/logo.png'
import { Link } from 'react-router-dom'

const Navbar = () => {
  return (
      <nav className='px-4 md:px-0 max-w-[920px] m-auto flex items-center justify-between py-2'>
        <div>
            <Link to={'/'} className='text-lg font-bold'><img src={Logo} alt="" className='h-8' /></Link>
        </div>
        <div className='group'><button className='bg-white w-10 h-10 rounded text-lg block md:hidden hover:bg-gradient-to-tr from-blue-500 to-purple-500 hover:text-white'><i class="ri-menu-3-line"></i></button>
        <ul className='md:flex hidden group-hover:flex md:items-center items-start gap-5 text-xs font-medium md:relative absolute bg-white md:bg-inherit flex-col md:flex-row top-14 md:top-1 right-5 md:right-0 rounded-md md:rounded-none drop-shadow-md md:drop-shadow-none p-4 md:p-0 w-[60%] md:w-max'>
            <li><Link to={'/'} className=' hover:underline'>Home</Link></li>
            <li><Link to={'/tools'} className=' hover:underline'>Tools</Link></li>
            <li><a href="#Features" className=' hover:underline'>Features</a></li>
            <li><Link to={'/blog'} className=' hover:underline'>News & Blogs</Link></li>
            <li><a href="#about" className=' hover:underline'>About Us</a></li>
            <li className='flex items-center gap-1.5'>
                <Link to={'/contact'} className='border border-gray-600 py-1.5 px-2 rounded-md hover:border-purple-500 hover:bg-purple-500 hover:bg-opacity-20'>Support<i class="ri-arrow-right-line"></i></Link>
            </li>
        </ul>
        </div>
      </nav>
  )
}

export default Navbar
