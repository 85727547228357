import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from './components/Navbar';
import config from './config.json';

const Tools = () => {
  const [tools, setTools] = useState([]);


  // Fetch tools data from the API
  useEffect(() => {
    axios
      .get(`${config.apiUrl}/tools`)
      .then(response => {
        const updatedTools = response.data.tools.map(tool => ({
          ...tool,
          img1: tool.img1.replace('public\\', ''),  // Remove 'public\\' from img1 path
          img2: tool.img2.replace('public\\', '')   // Remove 'public\\' from img2 path
        }));
        setTools(updatedTools); // Store the modified tools data in state
      })
      .catch(error => {
        console.error('Error fetching tools:', error);
      });
  }, []);

  return (
    <div className="bg-slate-100 text-black">
      <Navbar />
      <div className="bg-gradient-to-tl to-[#a855f730] from-[#3b82f630] -mt-12">
        <div className="max-w-[920px] m-auto px-4 md:px-0 p-28">
          <h1 className="text-5xl font-bold gradient-text">Our Tools & Apps</h1>
          <p className="mt-5 w-[80%]">
            We’re dedicated to supporting educational institutions with cutting-edge AI applications that enhance efficiency, performance, and academic integrity. Our innovative tools are designed to meet the diverse needs of universities, colleges, and language centers.
          </p>
        </div>
      </div>

      <main className="max-w-[920px] m-auto px-4 md:px-0">
        <div className="mt-28">
          {tools.map(tool => (
            <div key={tool._id} className="bg-slate-200 h-max md:mt-16 mt-[28rem] md:col-span-2 grid md:grid-cols-2 grid-cols-1 group border border-slate-400 hover:bg-gradient-to-tl from-blue-500 to-purple-500 hover:text-white p-4 rounded-lg">
              <div className="relative">
                <img src={`${config.apiUrl}/${tool.img1}`} alt={tool.title} className="h-96 w-48 object-cover rounded-md border-2 border-slate-600 absolute bottom-10 left-10" />
                <img src={`${config.apiUrl}/${tool.img2}`} alt={tool.title} className="h-96 w-48 object-cover rounded-md border-2 border-slate-600 absolute bottom-0 right-10" />
              </div>
              <div>
                <h3 className="text-lg font-bold mt-2">{tool.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: tool.description }} />
                <a href={tool.link} target='blank' className="bg-purple-500 bg-opacity-15 py-2 px-4 font-semibold rounded-md group-hover:bg-white group-hover:text-black mt-5 block w-max border border-purple-500">
                  Get The App!
                </a>
              </div>
            </div>
          ))}
        </div>
      </main>

      <footer className="bg-white text-center p-3 mt-20">
        <p className="text-slate-400">© 2024 Appverse Tech LLC. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Tools;
